import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClarityModule } from '@clr/angular';
import { OAuthModule } from 'angular-oauth2-oidc';
import { CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';
import { AlertComponent } from './alerts/alert.component';
import { InstitutionAlertComponent } from './alerts/institution-alerts/institution-alert.component';
import {
  AdditionalYearsClient,
  API_BASE_URL,
  AttachmentsClient,
  BookmarkFieldsClient,
  COWorklistClient,
  DAWorklistClient,
  EmailsClient,
  EmployeeClient,
  FFATAReportClient,
  FilesClient,
  InstitutionsClient,
  OutgoingSubsStatusReportClient,
  ProjectsClient,
  ProjectSearchClient,
  ProposalClient,
  ReturnCodesClient,
  ReturnReportClient,
  SubawardsClient,
  SubrecipientQuestionsClient,
  UserProfilesClient,
  WashUContactsClient,
  CollaboratorsExecutedSubsClient,
  SentDistFFATAReportClient,
  UtilityClient,
  OsrsReviewReportClient,
  InstitutionReportClient,
  InstitutionAuditReportClient,
  CchRollupReportClient,
  AnnouncementsClient,
  TrainingsClient,
  ContactListClient,
  ShortcutLinksClient,
  SubrecipientStatusClient,
} from './api.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './auth.service';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { DateComponent } from './components/date/date.component';
import { CardFileUploadComponent } from './components/file-upload/card-file-upload.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { CurrencyInputComponent } from './components/input/currency-input.component';
import { InputComponent } from './components/input/input.component';
import { LocalTimePipe } from './components/local-time/local-time.pipe';
import { RadioComponent } from './components/radio/radio.component';
import { ReadonlyAddressComponent } from './components/readonly-address/readonly-address.component';
import { SelectComponent } from './components/select/select.component';
import { CurrencyStaticTextComponent } from './components/static-text/currency-static-text.component';
import { StaticTextComponent } from './components/static-text/static-text.component';
import { SubawardStatusSelectComponent } from './components/subaward-status-select/subaward-status-select.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { UploadButtonComponent } from './components/upload-button/upload-button.component';
import { YesNoComponent } from './components/yes-no/yes-no.component';
import { ContactOsrsComponent } from './contact-osrs/contact-osrs.component';
import { ButtonAreaComponent } from './form-layout/form-buttons.component';
import { ContentAreaComponent } from './form-layout/form-content.component';
import { ButtonFormLayoutComponent } from './form-layout/form-layout.component';
import { AddressComponent } from './institution-details/address/address.component';
import { DeleteModalComponent } from './institution-details/delete-modal/delete-modal.component';
import { InstitutionAddressComponent } from './institution-details/institution-address/institution-address.component';
import { InstitutionContactComponent } from './institution-details/institution-contact/institution-contact.component';
import { InstitutionConversionComponent } from './institution-details/institution-conversion/institution-conversion.component';
import { InstitutionDetailsComponent } from './institution-details/institution-details.component';
import { InstitutionHeaderComponent } from './institution-details/institution-header/institution-header.component';
import { InstitutionSearchComponent } from './institution-search/institution-search.component';
import { InstitutionsGridComponent } from './institution-search/institutions-grid/institutions-grid.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { CentralOfficeWorklistComponent } from './reports/central-office-worklist/central-office-worklist.component';
import { CoWorklistResultGridComponent } from './reports/central-office-worklist/co-worklist-result-grid/co-worklist-result-grid.component';
import { DaWorklistResultGridComponent } from './reports/da-worklist/da-worklist-result-grid/da-worklist-result-grid.component';
import { DaWorklistComponent } from './reports/da-worklist/da-worklist.component';
import { ExcelExportService } from './reports/excel-export.service';
import { ExcelReadService } from './subawards/excel-read.service';
import { FfataReportComponent } from './reports/ffata-report/ffata-report.component';
import { OutgoingActivityReportComponent } from './reports/outgoing-activity-report/outgoing-activity-report.component';
import { OutgoingSubsStatusComponent } from './reports/outgoing-subs-status/outgoing-subs-status.component';
import { ReturnReportComponent } from './reports/return-report/return-report.component';
import { AddProjectComponent } from './subawards/add-project/add-project.component';
import { AdditionalDocumentControlFactory } from './subawards/additional-document/additional-document-control-factory';
import { AdditionalDocumentComponent } from './subawards/additional-document/additional-document.component';
import { AuditLogCardComponent } from './subawards/audit-history/audit-history-card/audit-log-card.component';
import { AuditHistoryComponent } from './subawards/audit-history/audit-history.component';
import { ChecklistComponent } from './subawards/checklist/checklist.component';
import { CommentCardComponent } from './subawards/comment/comment-card/comment-card.component';
import { CommentComponent } from './subawards/comment/comment.component';
import { ContactComponent } from './subawards/contact/contact.component';
import { DepartmentalAdminComponent } from './subawards/contact/departmental-admin/departmental-admin.component';
import { GenericContactComponent } from './subawards/contact/generic-contact/generic-contact.component';
import { GenericReadonlyContactComponent } from './subawards/contact/generic-readonly-contact/generic-readonly-contact.component';
import { PrincipalInvestigatorComponent } from './subawards/contact/principal-investigator/principal-investigator.component';
import { WashuContactComponent } from './subawards/contact/washu-contact/washu-contact.component';
import { EditProjectComponent } from './subawards/edit-project/edit-project.component';
import { EmployeeLookupGridComponent } from './subawards/edit-project/employee-lookup/employee-lookup-grid/employee-lookup-grid.component';
import { EmployeeLookupComponent } from './subawards/edit-project/employee-lookup/employee-lookup.component';
import { NoaControlFactory } from './subawards/edit-project/noa-control-factory.service';
import { NoticeOfAwardComponent } from './subawards/edit-project/notice-of-award/notice-of-award.component';
import { SubawardsGridComponent } from './subawards/edit-project/subawards-grid/subawards-grid.component';
import { FcoiComponent } from './subawards/fcoi/fcoi.component';
import { FfataComponent } from './subawards/ffata/ffata.component';
import { FullyExecutedAgreementComponent } from './subawards/fully-executed-agreement/fully-executed-agreement.component';
import { ProjectGridComponent } from './subawards/search-project/project-grid/project-grid.component';
import { SearchProjectComponent } from './subawards/search-project/search-project.component';
import { SelectAttachmentsComponent } from './subawards/select-attachments/select-attachments.component';
import { ContractAttachmentControlFactory } from './subawards/subaward-attachments/contract-attachment-control-factory';
import { ReturnCommentComponent } from './subawards/subaward-attachments/return-comment/return-comment.component';
import { SubawardAttachmentsComponent } from './subawards/subaward-attachments/subaward-attachments.component';
import { AwardedBudgetAndPerformancePeriodComponent } from './subawards/subaward-details/collaborator/awarded-budget-and-performance-period/awarded-budget-and-performance-period.component';
import { CollaboratorInstitutionGridComponent } from './subawards/subaward-details/collaborator/collaborator-search-modal/collaborator-institution-grid/collaborator-institution-grid.component';
import { CollaboratorInstitutionSearchComponent } from './subawards/subaward-details/collaborator/collaborator-search-modal/collaborator-institution-search.component';
import { CollaboratorComponent } from './subawards/subaward-details/collaborator/collaborator.component';
import { PrimaryPlaceOfPerformanceComponent } from './subawards/subaward-details/collaborator/primary-place-of-performance/primary-place-of-performance.component';
import { RiskNotificationEmailComponent } from './subawards/subaward-details/collaborator/risk-notification-email/risk-notification-email.component';
import { SubawardDetailsComponent } from './subawards/subaward-details/subaward-details.component';
import { MonitoringQuestionControlFactory } from './subawards/subaward-details/subrecipient-monitoring/monitoring-question-control-factory';
import { SubrecipientMonitoringComponent } from './subawards/subaward-details/subrecipient-monitoring/subrecipient-monitoring.component';
import { WashuInformationComponent } from './subawards/subaward-details/washu-information/washu-information.component';
import { SubawardExcelService } from './subawards/subaward/subaward-excel-service/subaward-excel.service';
import { SubawardComponent } from './subawards/subaward/subaward.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { AttachmentComponent } from './utilities/attachment/attachment.component';
import { BookmarkControlFactory } from './utilities/attachment/bookmarks-list/bookmark-control-factory';
import { BookmarksListComponent } from './utilities/attachment/bookmarks-list/bookmarks-list.component';
import { MonitoringQuestionFactory } from './utilities/monitoring-questions/monitoring-question-control-factory';
import { MonitoringQuestionsComponent } from './utilities/monitoring-questions/monitoring-questions.component';
import { ReturnCodeControlFactory } from './utilities/return-code/return-code-control-factory';
import { ReturnCodeComponent } from './utilities/return-code/return-code.component';
import { AttachmentGridComponent } from './utilities/search-attachment/attachment-grid/attachment-grid.component';
import { SearchAttachmentComponent } from './utilities/search-attachment/search-attachment.component';
import { SecurityComponent } from './utilities/security/security.component';
import { UserProfileFactory } from './utilities/security/user-profile-control-factory';
import { UniversityAddressesComponent } from './utilities/washu-contacts/university-addresses/university-addresses.component';
import { UniversityContactComponent } from './utilities/washu-contacts/university-contact/university-contact.component';
import { WashuContactsComponent } from './utilities/washu-contacts/washu-contacts.component';
import { CollaboratorsExecutedSubsComponent } from './reports/collaborators-executed-subs/collaborators-executed-subs.component';
import { SentDistFfataReportComponent } from './reports/sent-dist-ffata-report/sent-dist-ffata-report.component';
import { AssignedGAComponent } from './reports/assigned-ga-component/assigned-ga-component';
import { StateProvinceComponent } from './components/state-province-select/state-province.component';
import { CountryComponent } from './components/country-select/country.component';
import { SubawardModTypeSelectComponent } from './components/subaward-modtype-select/subaward-modtype-select.component';
import { AppUtilitySearchComponent } from './utilities/app-utility-search/app-utility-search.component';
import { UtilityItemDetailsComponent } from './utilities/utility-item-details/utility-item-details.component';
import { AppUtilitiesGridComponent } from './utilities/app-utility-search/app-utilities-grid/app-utilities-grid/app-utilities-grid.component';
import { FixedHeaderComponent } from './components/fixed-header/fixed-header.component';
import { OsrsReviewReportComponent } from './reports/osrs-review-report/osrs-review-report.component';
import { NewInstitutionReportComponent } from './reports/new-institution-report/new-institution-report.component';
import { InstitutionAuditReportComponent } from './reports/institution-audit-report/institution-audit-report.component';
import { ItemLookupSelectComponent } from './components/item-lookup-select/item-lookup-select.component';
import { OfficerControlFactory } from './subawards/ffata/officer-control-factory/officer-control-factory';
import { CchRollupReportComponent } from './reports/cch-rollup-report/cch-rollup-report.component';
import { PermissionsDirective } from './directives/permissions.directive';
import { JwtModule } from '@auth0/angular-jwt';
import { TimeoutInterceptor } from './TimeoutInterceptor';
import { UserDashbaordComponent } from './main-layout/user-dashbaord/user-dashbaord.component';
import { DashboardContactsComponent } from './main-layout/user-dashbaord/dashboard-contacts/dashboard-contacts.component';
import { DashboardTrainingComponent } from './main-layout/user-dashbaord/dashboard-training/dashboard-training.component';
import { DashboardAnnouncementsComponent } from './main-layout/user-dashbaord/dashboard-announcements/dashboard-announcements.component';
import { DashboardWeblinksComponent } from './main-layout/user-dashbaord/dashboard-weblinks/dashboard-weblinks.component';
import { ContactListComponent } from './utilities/contact-list/contact-list.component';
import { AnnouncementsComponent } from './utilities/announcements/announcements.component';
import { TrainingComponent } from './utilities/training/training.component';
import { ShortcutLinksComponent } from './utilities/shortcut-links/shortcut-links.component';
import { TrainingService } from './utilities/training/training.service';
import { SubrecipientStatusComponent } from './utilities/subrecipient-status/subrecipient-status.component';
import { SubStatusService } from './utilities/subrecipient-status/sub-status.service';
import { SubStatusControlFactory } from './utilities/subrecipient-status/sub-status-control-factory';

export const customCurrencyMaskConfig = {
  align: 'left',
  allowNegative: true,
  allowZero: true,
  decimal: '.',
  precision: 2,
  prefix: '$ ',
  suffix: '',
  thousands: ',',
  nullable: true,
  inputMode: CurrencyMaskInputMode.NATURAL,
};

@NgModule({
  declarations: [
    AppComponent,
    InstitutionDetailsComponent,
    InstitutionHeaderComponent,
    InstitutionAddressComponent,
    AddressComponent,
    InstitutionContactComponent,
    InputComponent,
    CurrencyInputComponent,
    ReadonlyAddressComponent,
    YesNoComponent,
    SelectComponent,
    SubawardStatusSelectComponent,
    InstitutionSearchComponent,
    InstitutionsGridComponent,
    DeleteModalComponent,
    AddProjectComponent,
    InstitutionConversionComponent,
    AlertComponent,
    EditProjectComponent,
    StaticTextComponent,
    CurrencyStaticTextComponent,
    DateComponent,
    TextareaComponent,
    SubawardDetailsComponent,
    SubawardAttachmentsComponent,
    WashuInformationComponent,
    CollaboratorComponent,
    ContactComponent,
    DepartmentalAdminComponent,
    PrincipalInvestigatorComponent,
    GenericContactComponent,
    GenericReadonlyContactComponent,
    WashuContactsComponent,
    SearchProjectComponent,
    ProjectGridComponent,
    FullyExecutedAgreementComponent,
    AwardedBudgetAndPerformancePeriodComponent,
    PrimaryPlaceOfPerformanceComponent,
    AwardedBudgetAndPerformancePeriodComponent,
    PrimaryPlaceOfPerformanceComponent,
    SearchProjectComponent,
    ProjectGridComponent,
    SubawardComponent,
    FileUploadComponent,
    CardFileUploadComponent,
    WashuContactComponent,
    CommentComponent,
    CommentCardComponent,
    SearchAttachmentComponent,
    AttachmentGridComponent,
    SubawardsGridComponent,
    UploadButtonComponent,
    LocalTimePipe,
    RiskNotificationEmailComponent,
    FfataComponent,
    NoticeOfAwardComponent,
    AttachmentComponent,
    BookmarksListComponent,
    CheckboxComponent,
    AdditionalDocumentComponent,
    AuditHistoryComponent,
    AuditLogCardComponent,
    ChecklistComponent,
    ReturnCodeComponent,
    CollaboratorInstitutionGridComponent,
    CollaboratorInstitutionSearchComponent,
    MonitoringQuestionsComponent,
    UnauthorizedComponent,
    MainLayoutComponent,
    FcoiComponent,
    DaWorklistComponent,
    DaWorklistResultGridComponent,
    SelectAttachmentsComponent,
    RadioComponent,
    ButtonFormLayoutComponent,
    ButtonAreaComponent,
    ContentAreaComponent,
    CentralOfficeWorklistComponent,
    CoWorklistResultGridComponent,
    UniversityAddressesComponent,
    UniversityContactComponent,
    SubrecipientMonitoringComponent,
    ReturnCommentComponent,
    ReturnReportComponent,
    FfataReportComponent,
    SecurityComponent,
    ContactOsrsComponent,
    OutgoingSubsStatusComponent,
    InstitutionAlertComponent,
    OutgoingActivityReportComponent,
    EmployeeLookupComponent,
    EmployeeLookupGridComponent,
    CollaboratorsExecutedSubsComponent,
    SentDistFfataReportComponent,
    AssignedGAComponent,
    StateProvinceComponent,
    CountryComponent,
    SubawardModTypeSelectComponent,
    AppUtilitySearchComponent,
    UtilityItemDetailsComponent,
    AppUtilitiesGridComponent,
    FixedHeaderComponent,
    OsrsReviewReportComponent,
    NewInstitutionReportComponent,
    InstitutionAuditReportComponent,
    ItemLookupSelectComponent,
    CchRollupReportComponent,
    PermissionsDirective,
    UserDashbaordComponent,
    DashboardContactsComponent,
    DashboardTrainingComponent,
    DashboardAnnouncementsComponent,
    DashboardWeblinksComponent,
    ContactListComponent,
    AnnouncementsComponent,
    TrainingComponent,
    ShortcutLinksComponent,
    SubrecipientStatusComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ClarityModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [window.environment.apiBaseUrl],
        sendAccessToken: true,
      },
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter,
        whitelistedDomains: [window.environment.apiBaseUrl],
      },
    }),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
  ],
  providers: [
    ProjectsClient,
    InstitutionsClient,
    UtilityClient,
    ProjectSearchClient,
    SubawardsClient,
    AttachmentsClient,
    NoaControlFactory,
    DAWorklistClient,
    COWorklistClient,
    BookmarkControlFactory,
    AdditionalDocumentControlFactory,
    ContractAttachmentControlFactory,
    ReturnCodeControlFactory,
    ReturnCodesClient,
    MonitoringQuestionFactory,
    MonitoringQuestionControlFactory,
    AuthService,
    ContactListClient,
    TrainingsClient,
    TrainingService,
    AnnouncementsClient,
    ShortcutLinksClient,
    FilesClient,
    EmailsClient,
    AdditionalYearsClient,
    ExcelExportService,
    ExcelReadService,
    SubawardExcelService,
    SubrecipientQuestionsClient,
    WashUContactsClient,
    ReturnReportClient,
    OsrsReviewReportClient,
    CchRollupReportClient,
    InstitutionReportClient,
    InstitutionAuditReportClient,
    FFATAReportClient,
    UserProfileFactory,
    ProposalClient,
    BookmarkFieldsClient,
    OutgoingSubsStatusReportClient,
    UserProfilesClient,
    ProposalClient,
    EmployeeClient,
    CollaboratorsExecutedSubsClient,
    SentDistFFATAReportClient,
    OfficerControlFactory,
    SubrecipientStatusClient,
    SubStatusService,
    SubStatusControlFactory,
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
    { provide: API_BASE_URL, useValue: window.environment.apiBaseUrl },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: initializer,
      deps: [AuthService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function initializer(authService: AuthService): () => Promise<void> {
  return async () => await authService.initialize();
}

export function tokenGetter() {
  return localStorage.getItem('access_token');
}
