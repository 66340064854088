import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { RequiredItemsChecklistService } from '../../subaward/required-items-checklist.service';
import {
  UserProfilesClient,
  SubawardsClient,
  SubrecipientStatusVm,
} from 'src/app/api.service';
import { AuthService } from 'src/app/auth.service';
import { SubawardsService } from '../../subawards.service';
import { FeatureService } from '../../../utilities/feature.service';
import { SubStatusService } from '../../../utilities/subrecipient-status/sub-status.service';

@Component({
  selector: 'subs-washu-information',
  templateUrl: './washu-information.component.html',
})
export class WashuInformationComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() showSubawardId: boolean;
  @Input() showComplianceChecks: boolean;
  @Input() showGenerateAgreementNumber: boolean;
  @Input() validatingGrantWorkTag: boolean;
  @Output() grantWorkTagEntered = new EventEmitter<string>();
  @Output() poCoRequestIdEntered = new EventEmitter<string>();

  assignedGAOptions: Array<string> = [];
  activeStatusList: SubrecipientStatusVm[] = [];
  isActiveFeatureGrantWorkTag = true;
  hasAccessToOsrsStatuses = false;
  psoDisabled = false;

  constructor(
    private checklistService: RequiredItemsChecklistService,
    private subawardsService: SubawardsService,
    private userProfileClient: UserProfilesClient,
    private subawardsClient: SubawardsClient,
    private authService: AuthService,
    private featureService: FeatureService,
    private subStatusService: SubStatusService,
  ) {}

  ngOnInit(): void {
    this.subStatusService.ActiveStatuses.subscribe(async value => {
      if (value !== undefined && value !== null) {
        this.activeStatusList = value;
      }
    });
    this.subawardsService.OsrsGaList.subscribe(async value => {
      const currentlyAssignedGA = this.form.controls.assignedGA.value;
      if (value) {
        value.forEach(u => {
          this.assignedGAOptions = value;
          if (
            currentlyAssignedGA &&
            !this.assignedGAOptions.includes(currentlyAssignedGA)
          ) {
            this.assignedGAOptions.push(currentlyAssignedGA);
          }
        });
      }
    });
    this.hasAccessToOsrsStatuses = this.authService.hasPermission(
      'AccessStatusOSRS',
    );
    this.featureService.featureEnabled('WDGrantWorkTag').subscribe(feature => {
      if (feature.featureName.toLowerCase() === 'wdgrantworktag') {
        this.isActiveFeatureGrantWorkTag = feature.featureEnabled;
      }
    });
    this.form.controls.poCoEncumbId.valueChanges.subscribe(value => {
      this.subawardsService.setPoCoEncumbId(value);
    });
    this.form.controls.poCoDocId.valueChanges.subscribe(() => {
      if (!!this.form.controls.poCoDocId.value) {
        this.form.controls.poCoDocId.patchValue(
          this.form.controls.poCoDocId.value.toUpperCase(),
          {
            emitEvent: false,
          },
        );
      }
    });

    this.form.controls.supplierContractId.valueChanges.subscribe(value => {
      this.subawardsService.setSupplierContractId(value);
    });

    // Story 779 set status to "Opened by OSRS" when a GA is assigned and the status is "Submitted to OSRS"
    this.form.controls.assignedGA.valueChanges.subscribe(GA => {
      this.form.controls.assignedGA.setValue(GA, {
        onlySelf: true,
        emitEvent: false,
      });
      const status = this.form.controls.status.value;
      if (status === 'SO' && GA !== '' && GA != null) {
        this.form.controls.status.setValue('OBO');
      }
    });
  }

  private isOsrsRoleGroupUser() {
    if (
      this.authService.hasPermission('OsrsAuthorityGlobalAccess') ||
      this.authService.hasPermission('OsrsGaGlobalAccess') ||
      this.authService.hasPermission('OsrsGaAdminGlobalAccess')
    ) {
      return true;
    } else {
      return false;
    }
  }

  generateAgreementNumber() {
    const date = new Date();
    let fy = '';
    if (date.getMonth() <= 6) {
      fy = date.getFullYear().toString();
    } else {
      fy = (date.getFullYear() + 1).toString();
    }
    this.subawardsClient.getSequence().subscribe(val => {
      this.form.controls.agreementNumber.setValue(val);
      if (this.form.controls.trackingNumber) {
        const trackingNumber = this.form.controls.trackingNumber.value;
        if (!trackingNumber || trackingNumber === '') {
          this.form.controls.trackingNumber.setValue(val);
        }
      }
    });
  }

  canGenerateNumber() {
    const status = this.form.controls.status.value;
    const allowedStatus = [
      'SRV',
      'SO',
      'RC',
      'RD',
      'OBO',
      'POC',
      'POA',
      'PT',
      'PA',
      'OR',
      'SMA',
      'IN',
      'STS',
      'SR',
      'RA',
      'SD',
      'OUO',
      'VD',
      'IA',
    ];
    return (
      this.authService.hasPermission('WidgetOsrsOnlyUpdate') &&
      allowedStatus.includes(status)
    );
  }

  viewOnlyUser(): boolean {
    return (
      this.authService.hasPermission('ReadOnlyGlobal') || this.viewOnlyDAUser()
    );
  }

  viewOnlyDAUser(): boolean {
    const isViewOnlyDAUser: boolean =
      this.authService.hasPermission('DepartmentAdminGlobalAccess') &&
      !this.isInDAStatus();
    return isViewOnlyDAUser;
  }

  isInDAStatus(): boolean {
    const status = this.form.controls.status.value;

    return ['IP', 'AC', 'CIC', 'PSO', 'SRV', 'SO', 'RC', 'RD'].includes(status);
  }

  validateGrantWorkTag() {
    if (this.form.controls.grantWorkTag.value) {
      this.grantWorkTagEntered.emit(this.form.controls.grantWorkTag.value);
    }
  }

  copyToSupplierContractId() {
    this.poCoRequestIdEntered.emit(this.form.controls.poCoDocId.value);
  }

  clearPreviousAssignedGA() {
    this.form.controls.previousAssignedGA.patchValue('');
    this.form.controls.previousAssignedGAFirstName.patchValue('');
    this.form.controls.previousAssignedGALastName.patchValue('');
  }
}
