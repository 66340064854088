import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.authService.user.pipe(
      map(user => {
        if (
          user &&
          (user.role == null ||
            user.role.length === 0 ||
            user.role === undefined)
        ) {
          this.router.navigate(['contact-osrs']);
        }
        return (
          !!user || this.router.parseUrl(`unauthorized?redirect=${state.url}`)
        );
      }),
    );
  }
}
