<div class="card" [formGroup]="form">
  <div class="card-header">Collaborator Information</div>
  <div class="card-block">
    <div class="clr-row">
      <div class="clr-col-2">
        <subs-static-text
          class="clr-col-5"
          labelLength="6"
          label="Selected Institution"
          controlName="institutionId"
        ></subs-static-text>
      </div>
      <div class="clr-col-10 clr-form-control">
        <button
          [disabled]="
            !daStatusList.includes(currentSubawardStatus) && !osrsUser
          "
          type="button"
          class="clr-col-5 btn btn-icon"
          style="margin-top: 0.8rem; max-width: 18%"
          (click)="showInstitutionSearch = true"
          aria-label="search institutions"
          data-test-id="search-icon"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Select institution"
        >
          Select Institution
          <clr-icon shape="search"></clr-icon>
        </button>
        <button
          [disabled]="
            (!canEditRefreshInstitution ||
              !daStatusList.includes(currentSubawardStatus)) &&
            !osrsUser
          "
          type="button"
          class="clr-col-5 btn btn-icon"
          style="margin-top: 0.8rem; max-width: 15%"
          (click)="editInstitution()"
          aria-label="search institutions"
          data-test-id="edit-icon"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Edit institution"
        >
          Edit Institution
          <clr-icon shape="note" title="Edit"></clr-icon>
        </button>
        <button
          [disabled]="
            !daStatusList.includes(currentSubawardStatus) && !osrsUser
          "
          type="button"
          class="clr-col-5 btn btn-icon"
          style="margin-top: 0.8rem; max-width: 15%"
          (click)="addInstitution()"
          aria-label="search institutions"
          data-test-id="add-icon"
          data-toggle="tooltip"
          data-placement="bottom"
          [title]="addInstitutionTooltip"
        >
          Add Institution
          <clr-icon shape="add-text" [title]="addInstitutionTooltip"></clr-icon>
        </button>
        <button
          type="button"
          class="clr-col-5 btn btn-icon"
          style="margin-top: 0.8rem; max-width: 25%"
          (click)="refreshInstitution()"
          aria-label="search institutions"
          data-test-id="refresh-icon"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Refresh institution record"
        >
          Refresh Institution Record
          <clr-icon shape="refresh" title="Refresh"></clr-icon>
        </button>
      </div>
    </div>
    <div class="clr-row">
      <subs-static-text
        class="clr-col-12"
        controlName="institutionName"
        label="Institution"
        labelLength="6"
        maxLength="254"
      ></subs-static-text>
    </div>

    <div class="clr-row">
      <subs-static-text
        class="clr-col-md"
        controlName="ein"
        label="EIN"
        labelLength="6"
      ></subs-static-text>
      <subs-static-text
        class="clr-col-md"
        controlName="riskCategory"
        label="Risk Category"
        labelLength="6"
      ></subs-static-text>
      <subs-yes-no
        class="clr-col-md"
        controlName="registeredInSamGov"
        label="Registered in sam.gov"
        labelLength="6"
        [disabled]="true"
      >
      </subs-yes-no>
    </div>

    <div class="clr-row">
      <clr-input-container class="clr-col-md">
        <label class="clr-col-12 clr-col-md-auto" style="width: 6rem"
          >Institution Status</label
        >
        <input
          clrInput
          class="clr-col-12 clr-col-md"
          [value]="this.statusValue"
          readonly="true"
        />
      </clr-input-container>

      <div class="clr-col-md">
        <subs-static-text
          controlName="congressionalDistrict"
          label="Congressional District"
          labelLength="6"
        ></subs-static-text>
      </div>

      <subs-static-text
        class="clr-col-md"
        controlName="parentDuns"
        label="Parent DUNS"
        labelLength="6"
      ></subs-static-text>
    </div>

    <div class="clr-row">
      <subs-static-text
        class="clr-col-md"
        controlName="dunsNumber"
        label="DUNS Number"
        labelLength="6"
      ></subs-static-text>

      <subs-yes-no
        class="clr-col-md"
        controlName="foreignInstitution"
        [disabled]="true"
        label="Foreign Institution"
        labelLength="6"
      >
      </subs-yes-no>

      <subs-static-text
        class="clr-col-md"
        controlName="parentCongressionalDistrict"
        label="Parent Congressional District"
        labelLength="6"
      ></subs-static-text>
    </div>

    <div class="clr-row">
      <subs-static-text
        class="clr-col-md"
        controlName="uniqueEntityId"
        label="UEI"
        labelLength="6"
      ></subs-static-text>
      <subs-static-text
        class="clr-col-md-8"
        controlName="supplierId"
        label="Supplier ID"
        labelLength="6"
      ></subs-static-text>
    </div>

    <div class="clr-row">
      <p class="clr-col-12 clr-col-md-8">
        In your Business or organization's previous fiscal year, did your
        business or organization (including parent organization, all branches,
        and all affiliates worldwide) receive (1) 80 percent or more of your
        annual gross revenues in U.S. federal contracts, subcontracts, loans,
        grants, subgrants, and/or cooperative agreements; and (2) $25,000,000 or
        more in annual gross revenues from U.S. federal contracts, subcontracts,
        loans, grants, subgrants, and/or cooperative agreements?
      </p>

      <subs-yes-no
        class="clr-col-12 clr-col-md-4"
        controlName="top5Q1"
        [disabled]="true"
        hideLabel
      >
      </subs-yes-no>
    </div>

    <div class="clr-row">
      <p class="clr-col-12 clr-col-md-8">
        Does the public have access to information about the compensation of the
        senior executives in your business or organization (including parent
        organization, all branches, and all affiliates worldwide) through
        periodic reports filed under section 13(a) or 15(d) of the Securities
        Exchange Act of 1934 (15 U.S.C. 78m(a), 78o(d)) or section 6104 of the
        Internal Revenue Code of 1986?
      </p>

      <subs-yes-no
        class="clr-col-12 clr-col-md-4"
        controlName="top5Q2"
        [disabled]="true"
        hideLabel
      >
      </subs-yes-no>
    </div>

    <div class="clr-row">
      <p class="clr-col-12 clr-col-md-12" style="color: #a51417">
        * REQUIRED: Brief Description of Action (New Sub Award; Termination;
        Adding Additional Year; Adding additional Funds for Current Year;
        Reducing Awarded Amount; etc.)
      </p>
    </div>

    <div class="clr-row">
      <subs-textarea
        class="clr-col"
        labelLength="4"
        label="Action Description"
        controlName="descriptionOfAction"
        placeholder="REQUIRED: Brief Description of Action (New Sub Award; Termination; Adding Additional Year; Adding additional Funds for Current Year; Reducing Awarded Amount, etc.)"
        maxLength="4000"
      >
        errorMessage="Please enter a Description of the Action prior to
        submitting to OSRS."
      </subs-textarea>
    </div>

    <div class="clr-row">
      <subs-yes-no
        *ngIf="isModifiedAgreement"
        class="clr-col-lg-5"
        controlName="fundNumberChanged"
        label="Has the Fund Number Changed from the Previous Year?"
      >
      </subs-yes-no>
    </div>
  </div>
</div>

<subs-risk-notification-email
  *ngIf="showRiskNotificationEmailComponent()"
  (financialEmailClicked)="this.financialEmailClicked.emit(true)"
>
</subs-risk-notification-email>

<div *ngIf="displayAllComponents" [formGroup]="form">
  <div class="clr-row-custom">
    <div id="collaborator-institutional-card" class="card clr-col-6">
      <div class="card-block">
        <div class="clr-row"></div>
        <div class="clr-row">
          <subs-card-file-upload
            id="collaborator-institutional-upload"
            title="Institutional Information Spreadsheet"
            fileNameControlName="institutionInformationFileName"
            fileIdControlName="institutionInformationFileId"
            dateControlName="institutionInformationDateUploaded"
            buttonLabel="import"
            [canEdit]="
              editColSpreadsheetStatusList.includes(currentSubawardStatus)
            "
            [callback]="callback"
            [acceptedMimeTypes]="[
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              'application/vnd.ms-excel'
            ]"
            acceptedMimeTypesErrorMessage="The collaborator institution information must be an Excel spreadsheet."
          ></subs-card-file-upload>
        </div>
      </div>
    </div>
    <div id="dmsp-card" class="card clr-col-6">
      <div class="card-header">Data Management Sharing Plan Requirement</div>
      <div class="card-block">
        <div class="clr-row">
          <subs-yes-no
            id="yes-no-dmsp"
            class="clr-col"
            controlName="dataMgmtSharingPlan"
            label="Does this project have a Data Management Sharing Plan requirement?"
          >
          </subs-yes-no>
        </div>
        <div class="clr-row">
          <subs-card-file-upload
            id="dmsp-upload"
            fileNameControlName="dataMgmtSharingPlanFileName"
            fileIdControlName="dataMgmtSharingPlanFileId"
            dateControlName="dataMgmtSharingPlanUploadedDate"
            [canEdit]="canUpload"
            style="width: 100%"
            [callback]="dmspCallback"
            [acceptedMimeTypes]="[
              'application/pdf',
              'application/msword',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            ]"
            acceptedMimeTypesErrorMessage="The Data Management Sharing Plan must be a PDF or a Word document."
          ></subs-card-file-upload>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-header">* Collaborator Scope of Work</div>
    <div class="card-block">
      <div class="clr-row">
        <subs-yes-no
          *ngIf="isModifiedAgreement"
          class="clr-col-lg-4"
          controlName="scopeOfWorkChanged"
          label="Has the
          scope of work changed?"
        >
        </subs-yes-no>
      </div>
      <div class="clr-row">
        <subs-yes-no
          class="clr-col-md"
          controlName="humanSubjects"
          label="Human Subjects"
        >
        </subs-yes-no>
        <subs-yes-no
          class="clr-col-md"
          controlName="animalSubjects"
          label="Animal Subjects"
        >
        </subs-yes-no>
        <subs-yes-no
          class="clr-col-md"
          controlName="clinicalResearch"
          label="Human Subjects Data"
          [tooltip]="tooltipContent"
        >
        </subs-yes-no>
        <ng-template #tooltipContent>
          <a
            role="tooltip"
            aria-haspopup="true"
            class="tooltip tooltip-md tooltip-bottom-left"
          >
            <clr-icon shape="info-circle" size="22"></clr-icon>
            <span class="tooltip-content"
              >Answer ‘Yes’ if any transfer of HSD is expected to occur.<br />
              Answer ‘No’ if no data transfer is expected.</span
            >
          </a>
        </ng-template>
      </div>
      <subs-file-upload
        id="scope-of-work-upload"
        label="File name"
        fileNameControlName="collaboratorScopeOfWorkFileName"
        fileIdControlName="collaboratorScopeOfWorkFileId"
        [acceptedMimeTypes]="[
          'application/pdf',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ]"
        acceptedMimeTypesErrorMessage="The collaborator scope of work must be a PDF or a Word document."
      ></subs-file-upload>
    </div>
  </div>

  <subs-awarded-budget-and-performance-period
    *ngIf="displayAllComponents"
    (sameAsGrantPeriodClicked)="this.sameAsGrantPeriodClicked.emit()"
    data-test-id="budget"
    [form]="form.controls.budget"
  ></subs-awarded-budget-and-performance-period>

  <subs-primary-place-of-performance
    *ngIf="displayAllComponents"
    (sameAsSignatoryClicked)="this.sameAsSignatoryClicked.emit()"
    data-test-id="place-of-performance"
    [form]="form.controls.placeOfPerformance"
  ></subs-primary-place-of-performance>

  <subs-collaborator-institution-search
    [isOpen]="showInstitutionSearch"
    (modalClose)="closeModal($event)"
    data-test-id="collaborator-search-modal"
  >
  </subs-collaborator-institution-search>

  <clr-modal
    [clrModalOpen]="showNewSubawardNotSavedModal"
    [clrModalClosable]="true"
  >
    <h3 class="modal-title">Subaward must be Saved before continuing</h3>
    <div class="modal-body">
      Please Save the Subaward before continuing with Institution changes.
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline"
        (click)="showNewSubawardNotSavedModal = false"
      >
        Close
      </button>
    </div>
  </clr-modal>
</div>
