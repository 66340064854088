import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  OnChanges,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { CollaboratorInstitutionSearchService } from './collaborator-search-modal/collaborator-institution-search.service';
import {
  Institution,
  InstitutionsClient,
  Subaward,
} from '../../../api.service';
import { InstitutionStatusNamesEnum } from 'src/app/enums/institution-status-names.enum';
import { ExcelReadService } from '../../excel-read.service';
import { SubawardsService } from '../../subawards.service';

@Component({
  selector: 'subs-collaborator',
  templateUrl: './collaborator.component.html',
  styleUrls: ['./collaborator.component.scss'],
  encapsulation: ViewEncapsulation.None, // Set ViewEncapsulation to None
})
export class CollaboratorComponent implements OnInit, OnDestroy, OnChanges {
  @Input() institutionSearchResults: Array<Institution>;
  @Input() form: UntypedFormGroup;
  @Input() displayAllComponents: boolean;
  @Input() isModifiedAgreement: boolean;
  @Input() subawardStatus: string;
  @Output() financialEmailClicked = new EventEmitter<boolean>();
  @Output() institutionalInformationUpload = new EventEmitter<Subaward>();
  @Output() sameAsGrantPeriodClicked: EventEmitter<void> = new EventEmitter<
    void
  >();
  @Output() sameAsSignatoryClicked: EventEmitter<void> = new EventEmitter<
    void
  >();
  @Output() onCollaboratorInstitutionSelect: EventEmitter<
    boolean
  > = new EventEmitter<boolean>();
  canUpload = false;
  showInstitutionSearch: boolean;
  isActiveView = true;
  statusValue = '';
  statusNames = InstitutionStatusNamesEnum;
  osrsUser = false;
  showNewSubawardNotSavedModal = false;
  loading = true;
  daStatusList = ['IP', 'AC', 'CIC', 'PSO', 'SRV', 'RC', 'RD'];
  editColSpreadsheetStatusList = [
    'IP',
    'AC',
    'CIC',
    'PSO',
    'SRV',
    'RC',
    'RD',
    'OBO',
    'IN',
  ];
  currentSubawardStatus = '';

  constructor(
    private router: Router,
    private authService: AuthService,
    private institutionsClient: InstitutionsClient,
    private institutionSearchService: CollaboratorInstitutionSearchService,
    private excelReadService: ExcelReadService,
    private cdr: ChangeDetectorRef,
    private subawardsService: SubawardsService,
  ) {
    this.institutionSearchResults = [];
  }

  get canEditRefreshInstitution(): boolean {
    return this.institutionIsSelected && !this.viewOnlyUser();
  }

  get addInstitutionTooltip(): string {
    return this.canEditRefreshInstitution
      ? 'Add institution'
      : 'Please Select an institution and Save the Subaward Record before Adding a new institution';
  }

  get institutionIsSelected(): boolean {
    return (
      this.form.controls.institutionId.value &&
      this.form.controls.institutionId.value !== ''
    );
  }

  ngOnInit(): void {
    if (
      this.authService.hasPermission('OsrsAuthorityGlobalAccess') ||
      this.authService.hasPermission('OsrsGaAdminGlobalAccess') ||
      this.authService.hasPermission('OsrsGaGlobalAccess')
    ) {
      this.osrsUser = true;
    }
    this.subawardsService.SubawardStatus.subscribe(value => {
      if (value) {
        this.currentSubawardStatus = value;
      }
    });
    if (this.canEditRefreshInstitution) {
      this.statusValue = this.statusNames[
        this.form.controls.institutionStatus.value
      ];
    }

    this.form.valueChanges.subscribe(() => {
      this.onCollaboratorInstitutionSelect.emit(!this.institutionIsSelected);
      this.resetForm();
    });

    this.form.get('dataMgmtSharingPlan').valueChanges.subscribe(value => {
      this.canUpload = !!value;
    });

    this.loading = false;
  }

  ngOnChanges(): void {
    if (!this.loading) {
      this.onCollaboratorInstitutionSelect.emit(!this.institutionIsSelected);
    }
  }

  closeModal(val: boolean) {
    this.showInstitutionSearch = val;
  }

  showRiskNotificationEmailComponent() {
    return (
      this.form.controls.institutionId.value &&
      !this.form.controls.riskCategory.value &&
      !this.viewOnlyUser()
    );
  }

  addInstitution() {
    const subID = this.form.controls.subawardId.value;
    if (subID === 0) {
      this.showNewSubawardNotSavedModal = true;
    } else {
      this.router.navigate(['/institution'], {
        queryParams: { subawardid: subID },
      });
    }
  }

  editInstitution() {
    const subID = this.form.controls.subawardId.value;
    if (subID == 0) {
      this.showNewSubawardNotSavedModal = true;
    } else {
      this.router.navigate(
        ['/institution/' + this.form.controls.institutionId.value],
        {
          queryParams: { subawardid: subID },
        },
      );
    }
  }

  refreshInstitution() {
    this.institutionsClient
      .get(this.form.controls.institutionId.value)
      .subscribe(result => {
        this.institutionSearchService.select(result);
      });
  }

  private resetForm() {
    this.statusValue = this.statusNames[
      this.form.controls.institutionStatus.value
    ];

    if (this.isActiveView) {
      this.cdr.detectChanges();
    }
  }

  ngOnDestroy() {
    this.isActiveView = false;
  }

  callback = (file: File) => {
    this.excelReadService.loadInsitutionalInformation(
      file,
      this.awaitFileLoadCallback,
    );
  };

  dmspCallback = (file: any) => {
    if (file !== null) {
      this.form.get('dataMgmtSharingPlanFileId').patchValue(file.id);
      this.form.get('dataMgmtSharingPlanFileName').patchValue(file.name);
      this.form.get('dataMgmtSharingPlanUploadedDate').patchValue(new Date());
      this.form
        .get('dataMgmtSharingPlanUploadedBy')
        .patchValue(this.authService.currentUser.name);
    } else {
      this.form.get('dataMgmtSharingPlanFileId').patchValue(null);
      this.form.get('dataMgmtSharingPlanFileName').patchValue(null);
      this.form.get('dataMgmtSharingPlanUploadedDate').patchValue(null);
      this.form.get('dataMgmtSharingPlanUploadedBy').patchValue('');
    }
  };

  awaitFileLoadCallback = (sub: Subaward) => {
    this.institutionalInformationUpload.emit(sub);
  };

  viewOnlyUser(): boolean {
    return (
      this.authService.hasPermission('ReadOnlyGlobal') || this.viewOnlyDAUser()
    );
  }

  viewOnlyDAUser(): boolean {
    const isViewOnlyDAUser: boolean =
      this.authService.hasPermission('DepartmentAdminGlobalAccess') &&
      !this.isInDAStatus();
    return isViewOnlyDAUser;
  }

  isInDAStatus(): boolean {
    const status = this.subawardStatus;

    return ['IP', 'AC', 'CIC', 'PSO', 'SRV', 'SO', 'RC', 'RD'].includes(status);
  }
}
