<clr-datagrid data-test-id="subs-grid">
  <clr-dg-column [clrDgSortOrder]="ascSort" [clrDgField]="'issuingDepartment'">
    <ng-container *clrDgHideableColumn="{ hidden: false }">
      Issuing Dept
    </ng-container>
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'institutionName'">
    <ng-container *clrDgHideableColumn="{ hidden: false }">
      Institution Name
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'grantWorkTag'">
    <ng-container *clrDgHideableColumn="{ hidden: false }">
      Grant Worktag
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'submittedDate'">
    <ng-container *clrDgHideableColumn="{ hidden: false }">
      Submitted to OSRS
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'amendmentNumber'">
    <ng-container *clrDgHideableColumn="{ hidden: false }">
      Amendment
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'agreementNumber'">
    <ng-container *clrDgHideableColumn="{ hidden: false }">
      Agreement Num
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'trackingNumber'">
    <ng-container *clrDgHideableColumn="{ hidden: false }">
      Tracking Num
    </ng-container></clr-dg-column
  >
  <clr-dg-column [clrDgField]="'status'">
    <ng-container *clrDgHideableColumn="{ hidden: false }">
      Status
    </ng-container></clr-dg-column
  >

  <clr-dg-row
    *clrDgItems="let result of subawardSummaryResults"
    (click)="navigateToSubaward(result.id)"
  >
    <clr-dg-action-overflow
      data-test-id="subaward-grid-overflow"
      (click)="$event.stopPropagation()"
    >
      <button
        class="action-item"
        (click)="
          setCollaboratorToDelete(result.id, result.institutionName, $event)
        "
        clrPopoverCloseButton
        data-test-id="delete-subaward-button"
      >
        Delete
      </button>
    </clr-dg-action-overflow>
    <clr-dg-cell>{{ result.issuingDepartment }}</clr-dg-cell>
    <clr-dg-cell>{{ result.institutionName }}</clr-dg-cell>
    <clr-dg-cell>{{ result.grantWorkTag }}</clr-dg-cell>
    <clr-dg-cell>{{ result.submittedDate | date: 'shortDate' }}</clr-dg-cell>
    <clr-dg-cell>{{ result.amendmentNumber }}</clr-dg-cell>
    <clr-dg-cell>{{ result.agreementNumber }}</clr-dg-cell>
    <clr-dg-cell>{{ result.trackingNumber }}</clr-dg-cell>
    <clr-dg-cell>{{ this.getStatus(result.status) }}</clr-dg-cell>
  </clr-dg-row>

  <clr-dg-footer data-test-id="subs-results-amount"
    >{{
      subawardSummaryResults.length === 0 ? 'No' : subawardSummaryResults.length
    }}
    result{{ subawardSummaryResults.length === 1 ? '' : 's' }}</clr-dg-footer
  >
</clr-datagrid>

<clr-modal [(clrModalOpen)]="!!collaboratorIdToDelete">
  <h3 class="modal-title">
    Confirm Delete?
  </h3>

  <div class="modal-body">
    <p>
      Are you sure you would like to delete the collaborator with institution
      name: <strong>{{ collaboratorNameToDelete }}</strong
      >?
    </p>
    <subs-alert [alertBehavior]="alertSubject$"></subs-alert>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      [clrLoading]="submitState | async"
      (click)="cancelDeleteConfirmation()"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      [clrLoading]="submitState | async"
      (click)="confirmDelete()"
      data-test-id="confirm-delete"
    >
      Delete
    </button>
  </div>
</clr-modal>
