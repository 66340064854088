<div class="card">
  <div class="card-header">
    Notice of Award Files
  </div>

  <div class="card-block" formArrayName="noticeOfAwards">
    <subs-alert [alertBehavior]="uploadButton.uploadError"> </subs-alert>

    <subs-upload-button
      #uploadButton
      (uploaded)="fileUploaded($event)"
      id="noa-upload"
      [acceptedMimeTypes]="['application/pdf']"
      acceptedMimeTypesErrorMessage="Notice of award files must be a PDF."
      >Add</subs-upload-button
    >

    <clr-datagrid data-test-id="noa-grid" style="margin-top:12px;">
      <clr-dg-column
        ><ng-container *clrDgHideableColumn="{ hidden: false }">
          File Name
        </ng-container></clr-dg-column
      >
      <clr-dg-column
        ><ng-container *clrDgHideableColumn="{ hidden: false }">
          Uploaded
        </ng-container></clr-dg-column
      >
      <clr-dg-column
        ><ng-container *clrDgHideableColumn="{ hidden: false }">
          Uploaded By
        </ng-container></clr-dg-column
      >
      <clr-dg-column>
        Select NoA
      </clr-dg-column>

      <clr-dg-row
        *ngFor="let award of noticeOfAwards | async; index as i"
        [formGroupName]="i"
      >
        <clr-dg-action-overflow>
          <button
            class="action-item"
            (click)="download(i)"
            clrPopoverCloseButton
            data-test-id="download"
          >
            Download
          </button>
          <button
            class="action-item"
            (click)="deleteAward(i)"
            clrPopoverCloseButton
            data-test-id="delete"
          >
            Delete
          </button>
        </clr-dg-action-overflow>

        <clr-dg-cell>{{ award.fileName }}</clr-dg-cell>
        <clr-dg-cell>{{ award.dateUploaded | localTime }}</clr-dg-cell>
        <clr-dg-cell>{{ award.uploadedBy }}</clr-dg-cell>
        <clr-dg-cell
          style="
          padding-top: 0;
          padding-bottom: 0;
          display: flex;
          align-items: center;
        "
        >
          <input
            type="checkbox"
            data-test-id="selected"
            clrCheckbox
            formControlName="selected"
          />
        </clr-dg-cell>
      </clr-dg-row>

      <clr-dg-footer
        >{{ (noticeOfAwards | async).length }} documents</clr-dg-footer
      >
    </clr-datagrid>
  </div>
</div>

<clr-modal [(clrModalOpen)]="!!fileNameToDelete">
  <h3 class="modal-title">
    Confirm Delete?
  </h3>

  <div class="modal-body">
    <p>
      Are you sure you want to delete <strong>{{ fileNameToDelete }}</strong
      >?
    </p>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="cancelDeleteConfirmation()"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="confirmDelete()"
      data-test-id="confirm-delete"
    >
      Delete
    </button>
  </div>
</clr-modal>
